var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom data rendering", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDataRendering) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-table", {
        staticClass: "mb-0",
        attrs: { fields: _vm.fields, items: _vm.items, responsive: "" },
        scopedSlots: _vm._u([
          {
            key: "cell(index)",
            fn: function(data) {
              return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
            }
          },
          {
            key: "cell(name)",
            fn: function(data) {
              return [
                _c("span", { staticClass: "text-nowrap" }, [
                  _vm._v(_vm._s(data.value.first + " - " + data.value.last))
                ])
              ]
            }
          },
          {
            key: "cell(Popularity)",
            fn: function(data) {
              return [
                _c("b-progress", {
                  attrs: {
                    value: data.value.value,
                    max: "100",
                    height: "7px",
                    variant: data.value.variant,
                    striped: ""
                  }
                })
              ]
            }
          },
          {
            key: "cell(order_status)",
            fn: function(data) {
              return [
                _c(
                  "b-badge",
                  { attrs: { pill: "", variant: data.value.variant } },
                  [_vm._v(" " + _vm._s(data.value.status) + " ")]
                )
              ]
            }
          },
          {
            key: "cell(price)",
            fn: function(data) {
              return [_vm._v(" " + _vm._s("$" + data.value) + " ")]
            }
          },
          {
            key: "cell()",
            fn: function(data) {
              return [_vm._v(" " + _vm._s(data.value) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }