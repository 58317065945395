var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Row & Col Style Option", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRowColStyle) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-table", {
        staticClass: "rounded-bottom",
        attrs: {
          items: _vm.items,
          fields: _vm.fields,
          "tbody-tr-class": _vm.rowClass
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }