var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { "no-body": "", title: "Basic Table" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [_c("b-table", { attrs: { responsive: "sm", items: _vm.items } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }