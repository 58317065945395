var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Table Responsive", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeResponsive) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-table", {
        staticClass: "mb-0",
        attrs: { responsive: "", items: _vm.items, fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "cell(Phone)",
            fn: function(data) {
              return [
                _c("span", { staticClass: "text-nowrap" }, [
                  _vm._v(" " + _vm._s(data.value) + " ")
                ])
              ]
            }
          },
          {
            key: "cell()",
            fn: function(data) {
              return [_vm._v(" " + _vm._s(data.value) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }