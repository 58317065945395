var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Table Kitchen Sink", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeKitchenSink) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-body", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between  flex-wrap" },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-1 mb-md-0",
                attrs: {
                  label: "Sort",
                  "label-size": "sm",
                  "label-align-sm": "left",
                  "label-cols-sm": "2",
                  "label-for": "sortBySelect"
                }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { size: "sm" } },
                  [
                    _c("b-form-select", {
                      attrs: { id: "sortBySelect", options: _vm.sortOptions },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(" none ")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.sortBy,
                        callback: function($$v) {
                          _vm.sortBy = $$v
                        },
                        expression: "sortBy"
                      }
                    }),
                    _c(
                      "b-form-select",
                      {
                        attrs: { size: "sm", disabled: !_vm.sortBy },
                        model: {
                          value: _vm.sortDesc,
                          callback: function($$v) {
                            _vm.sortDesc = $$v
                          },
                          expression: "sortDesc"
                        }
                      },
                      [
                        _c("option", { domProps: { value: false } }, [
                          _vm._v(" Asc ")
                        ]),
                        _c("option", { domProps: { value: true } }, [
                          _vm._v(" Desc ")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mb-0",
                attrs: {
                  label: "Filter",
                  "label-cols-sm": "2",
                  "label-align-sm": "left",
                  "label-size": "sm",
                  "label-for": "filterInput"
                }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { size: "sm" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "filterInput",
                        type: "search",
                        placeholder: "Type to Search"
                      },
                      model: {
                        value: _vm.filter,
                        callback: function($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter"
                      }
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { disabled: !_vm.filter },
                            on: {
                              click: function($event) {
                                _vm.filter = ""
                              }
                            }
                          },
                          [_vm._v(" Clear ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-table", {
        staticClass: "position-relative",
        attrs: {
          striped: "",
          hover: "",
          responsive: "",
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          items: _vm.items,
          fields: _vm.fields,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "sort-direction": _vm.sortDirection,
          filter: _vm.filter,
          "filter-included-fields": _vm.filterOn
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          },
          filtered: _vm.onFiltered
        },
        scopedSlots: _vm._u([
          {
            key: "cell(avatar)",
            fn: function(data) {
              return [_c("b-avatar", { attrs: { src: data.value } })]
            }
          },
          {
            key: "cell(status)",
            fn: function(data) {
              return [
                _c(
                  "b-badge",
                  { attrs: { variant: _vm.status[1][data.value] } },
                  [_vm._v(" " + _vm._s(_vm.status[0][data.value]) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "b-card-body",
        { staticClass: "d-flex justify-content-between flex-wrap pt-0" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "text-nowrap mb-md-0 mr-1",
              attrs: {
                label: "Per Page",
                "label-cols": "6",
                "label-align": "left",
                "label-size": "sm",
                "label-for": "sortBySelect"
              }
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "perPageSelect",
                  size: "sm",
                  inline: "",
                  options: _vm.pageOptions
                },
                model: {
                  value: _vm.perPage,
                  callback: function($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("b-pagination", {
                staticClass: "mb-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "first-number": "",
                  "last-number": "",
                  "prev-class": "prev-item",
                  "next-class": "next-item"
                },
                scopedSlots: _vm._u([
                  {
                    key: "prev-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "next-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronRightIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }