var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Row details support", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRowDetailsSupport) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              items: _vm.items,
              fields: _vm.fields,
              striped: "",
              responsive: ""
            },
            scopedSlots: _vm._u([
              {
                key: "cell(show_details)",
                fn: function(row) {
                  return [
                    _c(
                      "b-form-checkbox",
                      {
                        on: { change: row.toggleDetails },
                        model: {
                          value: row.detailsShowing,
                          callback: function($$v) {
                            _vm.$set(row, "detailsShowing", $$v)
                          },
                          expression: "row.detailsShowing"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.detailsShowing ? "Hide" : "Show") +
                            " "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(row) {
                  return [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-row",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("Full Name : ")]),
                                _vm._v(_vm._s(row.item.full_name) + " ")
                              ]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("Post : ")]),
                                _vm._v(_vm._s(row.item.post) + " ")
                              ]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("Email : ")]),
                                _vm._v(_vm._s(row.item.email) + " ")
                              ]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("City : ")]),
                                _vm._v(_vm._s(row.item.city) + " ")
                              ]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("Salary : ")]),
                                _vm._v(_vm._s(row.item.salary) + " ")
                              ]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "mb-1", attrs: { md: "4" } },
                              [
                                _c("strong", [_vm._v("Age : ")]),
                                _vm._v(_vm._s(row.item.age) + " ")
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "outline-secondary" },
                            on: { click: row.toggleDetails }
                          },
                          [_vm._v(" Hide Details ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(avatar)",
                fn: function(data) {
                  return [_c("b-avatar", { attrs: { src: data.value } })]
                }
              },
              {
                key: "cell(status)",
                fn: function(data) {
                  return [
                    _c(
                      "b-badge",
                      { attrs: { variant: _vm.status[1][data.value] } },
                      [_vm._v(" " + _vm._s(_vm.status[0][data.value]) + " ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }