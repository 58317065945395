var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Table Style Option", "no-body": "" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeStyleOption) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-card-body",
        [
          _c("b-form-group", [
            _c("label", { staticClass: "mb-0" }, [_vm._v("Table Options")]),
            _c(
              "div",
              { staticClass: "demo-inline-spacing" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.striped,
                      callback: function($$v) {
                        _vm.striped = $$v
                      },
                      expression: "striped"
                    }
                  },
                  [_vm._v(" Striped ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.bordered,
                      callback: function($$v) {
                        _vm.bordered = $$v
                      },
                      expression: "bordered"
                    }
                  },
                  [_vm._v(" Bordered ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.borderless,
                      callback: function($$v) {
                        _vm.borderless = $$v
                      },
                      expression: "borderless"
                    }
                  },
                  [_vm._v(" Borderless ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.outlined,
                      callback: function($$v) {
                        _vm.outlined = $$v
                      },
                      expression: "outlined"
                    }
                  },
                  [_vm._v(" Outlined ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.small,
                      callback: function($$v) {
                        _vm.small = $$v
                      },
                      expression: "small"
                    }
                  },
                  [_vm._v(" Small ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.hover,
                      callback: function($$v) {
                        _vm.hover = $$v
                      },
                      expression: "hover"
                    }
                  },
                  [_vm._v(" Hover ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.dark,
                      callback: function($$v) {
                        _vm.dark = $$v
                      },
                      expression: "dark"
                    }
                  },
                  [_vm._v(" Dark ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.fixed,
                      callback: function($$v) {
                        _vm.fixed = $$v
                      },
                      expression: "fixed"
                    }
                  },
                  [_vm._v(" Fixed ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.footClone,
                      callback: function($$v) {
                        _vm.footClone = $$v
                      },
                      expression: "footClone"
                    }
                  },
                  [_vm._v(" Foot Clone ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.noCollapse,
                      callback: function($$v) {
                        _vm.noCollapse = $$v
                      },
                      expression: "noCollapse"
                    }
                  },
                  [_vm._v(" No border collapse ")]
                )
              ],
              1
            )
          ]),
          _c(
            "b-form-group",
            { attrs: { label: "Head Variant", "label-cols-lg": "2" } },
            [
              _c(
                "b-form-radio-group",
                {
                  model: {
                    value: _vm.headVariant,
                    callback: function($$v) {
                      _vm.headVariant = $$v
                    },
                    expression: "headVariant"
                  }
                },
                [
                  _c("b-form-radio", { attrs: { value: null } }, [
                    _vm._v(" None ")
                  ]),
                  _c("b-form-radio", { attrs: { value: "light" } }, [
                    _vm._v(" Light ")
                  ]),
                  _c("b-form-radio", { attrs: { value: "dark" } }, [
                    _vm._v(" Dark ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "mb-0",
              attrs: {
                label: "Table Variant",
                "label-for": "table-style-variant",
                "label-cols-lg": "2"
              }
            },
            [
              _c("v-select", {
                attrs: {
                  dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                  label: "tableVariants",
                  options: _vm.tableVariants
                },
                model: {
                  value: _vm.tableVariant,
                  callback: function($$v) {
                    _vm.tableVariant = $$v
                  },
                  expression: "tableVariant"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          striped: _vm.striped,
          bordered: _vm.bordered,
          borderless: _vm.borderless,
          outlined: _vm.outlined,
          small: _vm.small,
          hover: _vm.hover,
          dark: _vm.dark,
          fixed: _vm.fixed,
          "foot-clone": _vm.footClone,
          "no-border-collapse": _vm.noCollapse,
          items: _vm.items,
          fields: _vm.fields,
          "head-variant": _vm.headVariant,
          "table-variant": _vm.tableVariant
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }